<template>
  <CheckboxSelect
      trackBy="value"
      optionLabel="label"
      label="Selecionar..."
      v-model="value.status"
      :options="statusOptions"
  />
</template>
<script>
import { healthPlanStatus } from "@/modules/SellingPanel/config/budget"
export default {
  name: "BudgetStatusSelect",
  components: {
    CheckboxSelect: () => import('@/components/CheckboxSelect'),
  },
  props: {
    value: Object
  },
  data() {
    return {
      statusOptions: []
    }
  },
  mounted() {

    healthPlanStatus.forEach((cfg, value) => {
      this.statusOptions.push({ label: cfg.label, value: value, style: cfg.style})
    })
  },
  methods: {
  }
}
</script>

<style lang="scss">
.request-types-combo.form-control {
  padding: 0;
}
</style>
<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }
</style>